<template>
  <div class="h5-solution-bottom">
    <p>立即注册，即刻开启您的鸿联云之旅</p>
    <van-button @click="reg"  size="small" class="btn-red btn">立即体验</van-button>
  </div>
</template>

<script>
export default {
  name: 'solution_bottom',
  methods:{
    reg(){
      this.$router.push(`/register`)
    },
  }
}
</script>

<style scoped lang="scss">
.h5-solution-bottom{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 110px;
  background: url("../../../assets/img/digitizing3.png") no-repeat;
  background-size: cover;
  >p{
    font-size: 16px;
    color: #262626;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .btn::v-deep{
    display: block;
    width: 82px;
    height: 26px;
  }
}

</style>
