<template>
<div class="internet-things">
   <div class="internet-things-banner" :style="bannerImg">
     <div>
       <p>{{ solutionInfo.name }}</p>
       <span>{{ solutionInfo.solSlogan }}</span>
       <van-button size="small" class="btn-red" @click="consult">国产替代咨询</van-button>
     </div>
   </div>

  <van-tabs
    v-model="active"
    scrollspy
    sticky
    color="#EA0B06"
    line-width="16px"
    :ellipsis=false
    class="tab-box"
  >
    <van-tab title="方案介绍" name="0">
      <div class="internet-things-desc-box" >
        <div class="internet-things-desc desc-title">
          <p>方案介绍</p>
          <div>
            <v-img :attachment-id="solutionInfo.solutionImg" :default-img="require('@/assets/img/kctp.png')"/>
            <p>{{solutionInfo.solutionDiscription}}</p>
          </div>
        </div>
      </div>
    </van-tab>

    <van-tab title="方案架构" v-if="usebool" name="1">
      <div class="internet-things-arc-box" >
        <div class="internet-things-arc desc-title">
          <p>方案架构</p>
          <div>
            <v-img class="img" :attachment-id="solutionInfo.frameworkImg" :default-img="require('@/assets/img/kctp.png')"/>
          </div>
        </div>
      </div>
    </van-tab>

    <van-tab title="方案优势" name="2">
      <div class="h5-home-solution-advantage" >
        <p>方案优势</p>
        <div>
          <van-swipe class="my-swipe" indicator-color="#EA0B06">
            <van-swipe-item v-for="i in solutionInfo.datSolutionAdvs" :key="i.id">
              <div class="h5-home-solution-advantage-list">
                <v-img :default-img="require('@/assets/img/things2.png')" />
                <div>
                  <p>{{i.name}}</p>
                  <div class="text">{{i.advDiscription}}</div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </van-tab>

    <van-tab title="应用价值" name="3">
      <div class="internet-things-val-box" >
        <div  class="internet-things-val desc-title">
        <p>应用价值</p>
        <div class="internet-things-val-content">
          <div v-for="item in solutionInfo.datSolutionValues" :key="item.id">
            <p>{{item.name}}</p>
            <p>{{item.valueDiscription}}</p>
          </div>
        </div>
        </div>
      </div>
    </van-tab>

  </van-tabs>
  <share :val="shareInfo"/>
  <solution-bottom v-if="!isLogin"></solution-bottom>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSolutionInfo } from '@/api/solution'
import VImg from '@/components/VImg'
import solution_bottom from '@/page/solution/components/solution_bottom'
import share from '../components/share.vue'
export default {
  name: 'internet_things',

  components: {
    VImg,
    share,
    'solution-bottom':solution_bottom,
  },

  data(){
    return{
      active: 0,
      solutionInfo: '',
      usebool:false,
      shareInfo: {
          title: '',
          desc: '',
          img: ''
      }
    }
  },

  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id
    },
    bannerImg:function (){
      let src=`${process.env.VUE_APP_OBS_PREFIX}/${this.solutionInfo.h5Banner}`
      return {backgroundImage: 'url(' + src + ')'}
    }
  },

  mounted(){
    document.title = '寻｜解决方案'
    this.loadGetSolutionInfo()
  },

  methods:{

    consult () {
      localStorage.removeItem('orderVal')
      this.$router.push('/demandMove')
    },

    loadGetSolutionInfo(){
      getSolutionInfo(this.id).then((res)=>{
        this.solutionInfo = res
        this.usebool=res.enableFrameworkImg
        this.shareInfo.title = res.name
        this.shareInfo.desc = res.solutionDiscription
        this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.h5Banner}`
      })
    },
  },



}
</script>

<style scoped lang="scss">
.internet-things{
  min-height: 100%;
  background: #f6f7f9;
  .internet-things-banner{
    height: 246px;
    color: #252b3a;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(90deg, #EEF3F9 0%, rgba(238,243,249,0.5000) 100%);
    }
    > img {
      width: 100%;
      height: 100%;
      display: block;
    }

    > div {
      padding: 40px 16px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;

      > p {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 6px;
      }

      > span {
        font-size: 12px;
        display: block;
        line-height: 17px;
        margin-bottom: 46px;
      }

      > bottom {
        display: block;
      }
    }
  }

  .desc-title {
    > p {
      box-sizing: border-box;
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
    }
  }

  .internet-things-desc-box {
   padding: 24px 16px;
    background: url("../../assets/img/digitizing2.png") no-repeat;
    background-size: cover;

    .internet-things-desc {

      > div {
        margin-top: 18px;
        padding: 16px;
        background: #fff;

        > img {
          width: 100%;
          height: 176px;
          display: block;
        }

        > p {
          font-size: 12px;
          line-height: 18px;
          text-align: justify;
          margin-top: 16px;
        }
      }
    }
  }

  .internet-things-arc-box {
    height: 291px;
    padding: 24px 16px;
    background: #FFFFFF;
    .internet-things-arc {
      > div {
        margin-top: 24px;

        .img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .h5-home-solution-advantage {
   padding: 24px 16px;
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      margin-bottom: 22px;
    }
    .h5-home-solution-advantage-list {
      background: #fff;
      //margin: 0 24px 24px 0;
      padding: 16px;
      display: flex;
      align-items: flex-start;
      > img {
        margin-right: 12px;
        width: 32px;
        height: 32px;
      }
      > div {
        flex: 1;
        > p {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 16px;
        }
        > .text {
          display: block;
          font-size: 12px;
          color: #8c8c8c;
          line-height: 18px;
        }
      }
    }


  }

  .internet-things-val-box{
    padding: 24px 16px;
    background: #FFFFFF;
    .internet-things-val{
      .internet-things-val-content{
        margin-top: 18px;
        >div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          padding: 32px 32px 24px;
          border: 1px solid #EEEEEE;
          >:nth-child(1){
            font-size: 14px;
            color: #333333;

          }
          >:nth-child(2){
            margin-top: 16px;
            font-size: 12px;
            color: #666666;
          }
        }
        :not(:first-child){
          border-top: none;
        }

      }
    }
  }




}
</style>
<style>



.van-swipe__indicators{
  bottom: 0;
}
.van-swipe__indicator{
  background: #eee;
  width: 24px;
  height: 2px;
  border-radius: 0;
}
.van-swipe{
  padding-bottom: 10px;
}
.internet-things .van-tabs--line .van-tabs__wrap{
  height: 48px;
}
</style>
